<template>
  <div class="school-page">
    <lazy-hydrate when-visible>
      <section class="school-page__banner">
        <ceny-banner
          v-if="cenyPage"
        />
        <school-zaochnoe-banner
          v-else-if="zaochnoePage"
        />
        <school-attestacii-banner
          v-else-if="attestaciiPage"
        />
        <school-vechernee-banner
          v-else-if="vecherneePage"
        />
        <school-semeinoe-banner
          v-else-if="semeinoePage"
        />
        <school-class-banner
          v-else-if="classPage"
        />
        <school-banner
          v-else
          :title="bannerTitle"
        />
      </section>
    </lazy-hydrate>

    <lazy-hydrate when-visible>
      <section
        v-if="!cenyPage && !forCom"
        class="school-page__certificate"
      >
        <main-certificate />
      </section>
    </lazy-hydrate>

    <lazy-hydrate when-visible>
      <section class="school-page__about">
        <school-about />
      </section>
    </lazy-hydrate>

    <lazy-hydrate when-visible>
      <section class="school-page__way">
        <school-way :for-com="forCom" />
      </section>
    </lazy-hydrate>

    <lazy-hydrate when-visible>
      <ceny-news v-if="cenyPage" />
    </lazy-hydrate>

    <section
      id="onlineSchoolAdvantages"
      class="school-page__unique"
    >
      <school-advantages class="school-page__advantages" />

      <lazy-hydrate when-visible>
        <school-knowledge
          v-if="!cenyPage && !classPage && !attestaciiPage && !vecherneePage && !semeinoePage"
          class="school-page__know"
        />
      </lazy-hydrate>

      <lazy-hydrate when-visible>
        <school-career-guidance
          v-if="!cenyPage && !classPage && !attestaciiPage && !vecherneePage && !semeinoePage"
          class="school-page__guidance"
        />
      </lazy-hydrate>

      <lazy-hydrate when-visible>
        <school-skills
          v-if="!cenyPage && !classPage && !attestaciiPage && !vecherneePage && !semeinoePage"
          class="school-page__skills"
        />
      </lazy-hydrate>

      <lazy-hydrate when-visible>
        <school-educ-route
          v-if="!cenyPage && !classPage && !attestaciiPage && !vecherneePage && !semeinoePage"
          class="school-page__route"
        />
      </lazy-hydrate>

      <lazy-hydrate when-visible>
        <school-team-projects
          v-if="!cenyPage && !classPage && !attestaciiPage && !vecherneePage && !semeinoePage"
          class="school-page__team"
        />
      </lazy-hydrate>

      <lazy-hydrate when-visible>
        <school-master-classes
          v-if="!cenyPage && !classPage && !vecherneePage && !semeinoePage"
        />
      </lazy-hydrate>
    </section>

    <lazy-hydrate when-visible>
      <school-education-forms
        v-if="cenyPage"
      />
    </lazy-hydrate>

    <lazy-hydrate when-visible>
      <section class="school-page__workers">
        <img
          id="school-page__workers_flower"
          src="/v2/school/workers/flower.webp"
          alt="flower"
        >
        <school-workers />
      </section>
    </lazy-hydrate>

    <lazy-hydrate when-visible>
      <section>
        <interactive-lessons />
      </section>
    </lazy-hydrate>

    <lazy-hydrate when-visible>
      <section>
        <parents-videos />
      </section>
    </lazy-hydrate>

    <lazy-hydrate when-visible>
      <olga-block />
    </lazy-hydrate>

    <lazy-hydrate when-visible>
      <section class="school-page__faq-accordion">
        <faq-accordion :page="faqAccordionPage" />
      </section>
    </lazy-hydrate>

    <lazy-hydrate when-visible>
      <section>
        <form-index
          :land-code="landCode"
          :for-com="forCom"
        />
      </section>
    </lazy-hydrate>

    <lazy-hydrate
      v-if="zaochnoePage || classPage || vecherneePage || semeinoePage"
      when-visible
    >
      <school-zaochnoe-seo v-if="zaochnoePage" />
      <school-vechernee-seo v-else-if="vecherneePage" />
      <school-semeinoe-seo v-else-if="semeinoePage" />
      <school-class-seo v-else-if="classPage" />
    </lazy-hydrate>
  </div>
</template>

<script>
import HeadMixin from '@/mixins/HeadMixin';

import LazyHydrate from 'vue-lazy-hydration';
import OlgaBlock from '../common/OlgaBlock.vue';

const CenyBanner = () => import('@/components/v2/school/ceny/CenyBanner.vue');
const CenyNews = () => import('@/components/v2/school/ceny/CenyNews.vue');
const SchoolEducationForms = () => import('@/components/v2/school/SchoolEducationForms.vue');
const SchoolBanner = () => import('@/components/v2/school/SchoolBanner.vue');
const SchoolAbout = () => import('@/components/v2/school/SchoolAbout.vue');
const FaqAccordion = () => import('@/components/v2/common/FaqAccordion/FaqAccordion.vue');
const ParentsVideos = () => import('@/components/v2/common/ParentsVideos.vue');
const FormIndex = () => import('@/components/v2/common/FormIndex.vue');
const SchoolWorkers = () => import('@/components/v2/common/SchoolWorkers/SchoolWorkers.vue');
const SchoolWay = () => import('@/components/v2/school/SchoolWay.vue');
const InteractiveLessons = () => import('@/components/v2/school/InteractiveLessons.vue');
const SchoolAdvantages = () => import('@/components/v2/school/SchoolAdvantages.vue');
const SchoolKnowledge = () => import('@/components/v2/school/SchoolKnowledge.vue');
const SchoolSkills = () => import('@/components/v2/school/SchoolSkills.vue');
const SchoolEducRoute = () => import('@/components/v2/school/SchoolEducRoute.vue');
const SchoolTeamProjects = () => import('@/components/v2/school/SchoolTeamProjects.vue');
const SchoolMasterClasses = () => import('@/components/v2/school/SchoolMasterClasses.vue');
const SchoolCareerGuidance = () => import('@/components/v2/school/SchoolCareerGuidance.vue');

const SchoolZaochnoeBanner = () => import('@/components/v2/school/SchoolZaochnoeBanner.vue');
const SchoolZaochnoeSeo = () => import('@/components/v2/school/SchoolZaochnoeSeo.vue');

const SchoolAttestaciiBanner = () => import('@/components/v2/school/SchoolAttestaciiBanner.vue');

const SchoolVecherneeBanner = () => import('@/components/v2/school/SchoolVecherneeBanner.vue');
const SchoolVecherneeSeo = () => import('@/components/v2/school/SchoolVecherneeSeo.vue');

const SchoolSemeinoeBanner = () => import('@/components/v2/school/SchoolSemeinoeBanner.vue');
const SchoolSemeinoeSeo = () => import('@/components/v2/school/SchoolSemeinoeSeo.vue');

const SchoolClassBanner = () => import('@/components/v2/school/SchoolClassBanner.vue');
const SchoolClassSeo = () => import('@/components/v2/school/SchoolClassSeo.vue');
const MainCertificate = () => import('@/components/v2/main/MainCertificate.vue');

export default {
  name: 'SchoolPageOld',
  components: {
    OlgaBlock,
    SchoolClassSeo,
    SchoolClassBanner,
    SchoolSemeinoeBanner,
    SchoolSemeinoeSeo,
    SchoolVecherneeBanner,
    SchoolVecherneeSeo,
    SchoolAttestaciiBanner,
    SchoolZaochnoeSeo,
    SchoolZaochnoeBanner,
    CenyNews,
    SchoolEducationForms,
    CenyBanner,
    LazyHydrate,
    SchoolBanner,
    SchoolAbout,
    SchoolAdvantages,
    InteractiveLessons,
    SchoolWay,
    SchoolWorkers,
    ParentsVideos,
    FaqAccordion,
    FormIndex,
    SchoolKnowledge,
    SchoolCareerGuidance,
    SchoolSkills,
    SchoolEducRoute,
    SchoolTeamProjects,
    SchoolMasterClasses,
    MainCertificate,
  },
  mixins: [HeadMixin],
  layout: 'DefaultLayoutV2',

  props: {
    landCode: {
      type: String,
      default: null,
    },
    forCom: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    // для head
    headScripts: [{
      // eslint-disable-next-line max-len,vue/max-len
      src: 'https://js.onef.pro/static/reg1f_v1.js?1f_pixel_id=24aed672-c5f3-4e78-ba7e-eed74b860cd1&event_type=visit&product=school_synergy_ru',
      defer: true,
      type: 'text/javascript',
    }],
    canonical: true,
  }),

  computed: {
    bannerTitle() {
      return this.forCom
        ? 'учись в&nbsp;любое время из&nbsp;любой точки мира'
        : 'Домашняя школа <br>с 5 по 11 класс';
    },

    cenyPage() {
      return this.$route.name === 'online-school-ceny';
    },
    zaochnoePage() {
      return this.$route.name === 'online-school-zaochnoe-obuchenie';
    },
    attestaciiPage() {
      return this.$route.name === 'online-school-attestacii-i-prikreplenie';
    },
    vecherneePage() {
      return this.$route.name === 'online-school-vechernee-obuchenie';
    },
    semeinoePage() {
      return this.$route.name === 'online-school-semeinoe-obuchenie';
    },
    classPage() {
      return Boolean(this.$route.name.match(/online-school-[0-9]{1,2}-klass/));
    },

    title() {
      if (this.zaochnoePage) {
        return 'Заочное обучение в онлайн-школе | Заочная школа Синергии с 5 по 11 класс';
      }

      if (this.attestaciiPage) {
        return 'Аттестации для семейной формы обучения и прикрепление к онлайн-школе Синергия';
      }

      if (this.vecherneePage) {
        return 'Набор на вечернее обучение с 5 по 11 класс в онлайн-школе Синергия!';
      }

      if (this.semeinoePage) {
        return 'Пройдите семейное обучение с 5 по 11 класс в онлайн-школе Синергия';
      }

      if (this.classPage) {
        const classNumber = this.$route.name.match(/[0-9]{1,2}/)?.[0] || 8;
        return `Онлайн-обучение в ${classNumber} классе | Дистанционная школа Синергия`;
      }

      return 'Домашняя школа «Синергия». Дистанционное онлайн-обучение с 5 по 11 класс';
    },
    description() {
      if (this.zaochnoePage) {
        // eslint-disable-next-line max-len,vue/max-len
        return 'Учитесь заочно в онлайн-школе Синергия из дома и получите аттестат государственного образца. Узнайте о всех преимущества заочной формы обучения в аккредитованной онлайн-школе с лицензией.';
      }

      if (this.attestaciiPage) {
        // eslint-disable-next-line max-len,vue/max-len
        return 'Для учащихся на семейной форме в онлайн-школе Синергия предоставляет услугу по прикреплению к школе и онлайн-аттестациям. Мы поможем вам пройти аттестацию в удобном онлайн формате и прикрепиться к нашей школе из любой точки России и мира.';
      }

      if (this.vecherneePage) {
        // eslint-disable-next-line max-len,vue/max-len
        return 'Получите школьное образование с аттестатом гос. образца на вечерней форме обучения в онлайн-школе Синергия. Пробная неделя бесплатно!';
      }

      if (this.semeinoePage) {
        // eslint-disable-next-line max-len,vue/max-len
        return 'Учитесь с комфортом на семейной форме обучения в онлайн-школе Синергия. Прикрепляйтесь к любой школе и пройдите аттестацию в удобном формате. Пробная неделя бесплатно!';
      }

      if (this.classPage) {
        const classNumber = this.$route.name.match(/[0-9]{1,2}/)?.[0] || 8;
        // eslint-disable-next-line max-len,vue/max-len
        return `Учитесь онлайн из дома с ${classNumber} класса в аккредитованной домашней школе Синергия. Обучение по государственным стандартам и ФГОС. Занятия проходят онлайн на платформе.`;
      }

      // eslint-disable-next-line max-len,vue/max-len
      return 'Онлайн-школа «Синергия» — Комфортная и поддерживающая среда, где обучение обретает смысл. Зачисление в лицензированную школу с государственной аккредитацией';
    },
    image() {
      if (this.vecherneePage) return '/v2/school/vechernee/share-image.jpg';
      if (this.semeinoePage) return '/v2/school/semeinoe/share-image.jpg';

      return '/v2/school/share-image.jpg';
    },

    faqAccordionPage() {
      if (this.zaochnoePage) return 'zaochnoe';
      if (this.attestaciiPage) return 'attestacii';
      if (this.vecherneePage) return 'vechernee';
      if (this.semeinoePage) return 'semeinoe';

      return 'school';
    },
  },

  mounted() {
    const onlineSchoolAdvantages = document.querySelector('#onlineSchoolAdvantages');
    const haveLicensesQuery = this.$route?.query?.licenses === '1';
    if (haveLicensesQuery && onlineSchoolAdvantages) {
      onlineSchoolAdvantages.scrollIntoView({ behavior: 'smooth' });
    }
  },
};
</script>

<style scoped lang="scss">
.school-page {
  display: flex;
  flex-direction: column;
  gap: 180px;
  padding-bottom: 140px;

  background-color: $gray-bg;
  overflow: hidden;

  @include media-down(laptop) {
    gap: 140px;
    padding-bottom: 120px;
  }

  @include media-down(tablet) {
    gap: 100px;
  }

  .school-page__certificate {
    padding-inline: calc(var(--scale) * 40);

    @include media-down($size-tablet) {
      padding-inline: calc(var(--scale) * 16);
    }

    @include media-down($size-mobile) {
      padding-inline: calc(var(--scale) * 12);
    }

    ::v-deep(*) {
      font-family: "Onest", sans-serif;
    }
  }

  .school-page__workers {
    position: relative;

    #school-page__workers_flower {
      position: absolute;
      left: 0;
      top: -330px;

      @include media-down(tablet) {
        display: none;
      }
    }
  }

  .school-page__advantages {
    margin-bottom: 180px;

    @include media-down(laptop) {
      margin-bottom: 140px;
    }

    @include media-down(tablet) {
      margin-bottom: 100px;
    }

    @include media-down(mobile) {
      margin-bottom: 80px;
    }
  }

  .school-page__banner {
    padding-bottom: 72px;
    margin-bottom: -72px;
    overflow: hidden;
  }

  &__about {
    margin-bottom: 40px;

    @include media-down(tablet) {
      margin-bottom: 20px;
    }
  }

  &__unique {
    padding-top: 170px;
    padding-bottom: 35px;

    background-color: $white-color;
    background-image:
      url('/v2/main/sfe/background.svg'),
      url('/v2/school/bg.svg');
    background-repeat: repeat, no-repeat;
    background-size: auto, cover;
    background-position: center, center;

    @include media-down(tablet) {
      padding-top: 70px;
      padding-bottom: 25px;

      background-image:
        url('/v2/main/sfe/background.svg'),
        url('/v2/school/bg-mob.svg');
      background-size: 950px, cover;
    }
  }

  &__know {
    margin-bottom: 280px;

    @include media-down(tablet) {
      margin-bottom: 80px;
    }
  }

  &__guidance {
    margin-bottom: 130px;
  }

  &__skills {
    margin-bottom: 150px;

    @include media-down(laptop) {
      margin-bottom: 100px;
    }
  }

  &__route {
    margin-bottom: 270px;

    @include media-down(laptop) {
      margin-bottom: 100px;
    }
  }

  &__team {
    margin-bottom: 225px;

    @include media-down(laptop) {
      margin-bottom: 100px;
    }
  }

  .school-page__way {
    position: relative;
  }

  ::v-deep .school-stories-banner {
    padding-bottom: 0;
  }
}
</style>
